<template>
  <div class="mid-area-1 mid-w-3 cont-1ast">
    <div v-if="!loading" class="stat-cnt">
      <div v-for="(stat, i) in formattedStats" :key="i" class="mid-area-1-cont">
        <div class="mid-area-1-cont-title wide-140">
          <span
            v-if="stat.title.toUpperCase() === 'REACH'"
            class="intip4"
            data-tool-tip="The number of unique households that have been exposed to an ad"
            >{{ stat.title }}</span
          >
          <span
            v-else-if="stat.title.toUpperCase() === 'FREQUENCY'"
            class="intip4 sm-box"
            data-tool-tip="The average number of ad exposures per household"
            >{{ stat.title }}</span
          >
          <span v-else-if="stat.title.toUpperCase() === 'VCR'">COMPLETION RATE</span>
          <span v-else>{{ stat.title }}</span>
          <div v-if="stat.title.toUpperCase() === 'ROAS'" class="revenue-conversion">
            <b-radio-tab-group
              :initial-group="revenueConversionOptions"
              class="option-selection-cont"
              @update="updateRevenueConversionOpt"
            />
          </div>
          <div v-if="stat.title.toUpperCase() === 'SPEND' && stat.ioPacing" class="pacing">
            <span
              v-if="!stat.ioPacingNoData"
              class="tpace-btn"
              @mouseenter="ioPacingMouseEnter"
              @mouseleave="ioPacingMouseLeave"
            >
              <font-awesome-icon class="tachometer" :icon="['fas', 'tachometer']" size="1x" />
              {{ formatIOPacing(stat.ioPacing.pacing.currentValue) }}
            </span>
            <span
              v-else
              class="tpace-btn"
              @mouseenter="ioPacingMouseEnter"
              @mouseleave="ioPacingMouseLeave"
            >
              <font-awesome-icon class="tachometer" :icon="['fas', 'tachometer']" size="1x" />NA
            </span>
            <div
              class="pacing-summary"
              :style="{ opacity: showIOPacing, zIndex: showIOPacing ? 3 : -3 }"
              @mouseenter="ioPacingMouseEnter"
              @mouseleave="ioPacingMouseLeave"
            >
              <span class="panel-title io-title">OVERALL IO PACING</span>
              <div v-if="!stat.ioPacingLoading && !stat.ioPacingNoData" class="iopacing-wrapper">
                <div class="data-wrap">
                  <span class="head">START</span>
                  <span class="data">{{ stat.ioPacing.startDate }}</span>
                </div>
                <div class="data-wrap">
                  <span class="head">END</span>
                  <span class="data">{{ stat.ioPacing.endDate }}</span>
                </div>
                <div class="circle-wrap">
                  <semi-circle
                    class="semi-circle"
                    :initial-up-value="stat.ioPacing.pacing.compareValue || 0"
                    :initial-down-value="stat.ioPacing.pacing.currentValue || 0"
                    :initial-percent-diff="stat.ioPacing.pacing.change || 0"
                  />
                </div>
                <div class="data-wrap">
                  <span class="head">BUDGET</span>
                  <b-numero class="data" :value="stat.ioPacing.budget || 0" type="currency" />
                </div>
                <div class="data-wrap">
                  <span class="head">SPEND</span>
                  <b-numero class="data" :value="stat.ioPacing.spend || 0" type="currency" />
                </div>
              </div>
              <b-loading-spinner v-else-if="stat.ioPacingLoading && !stat.ioPacingNoData" />
              <div
                v-else-if="!stat.ioPacingLoading && stat.ioPacingNoData"
                class="txt-align-center"
              >
                No data is available to show
              </div>
            </div>
          </div>
          <div v-if="stat.titleIncludes && stat.titleIncludes.length" class="title-plus-drop">
            <span class="psub psub-title">Includes:</span>
            <span v-for="(inc, j) in stat.titleIncludes" :key="j" class="psub">{{ inc }}</span>
          </div>
        </div>
        <div id="checkoutsamt" class="mid-area-1-amt">
          <b-numero :value="stat.value" :is-infinity="stat.isInfinity" :type="stat.valueType" />
        </div>
        <div
          id="checkoutsval"
          class="mid-area-1-amt-label"
          :class="[stat.subValueMuted ? 'muted' : '']"
        >
          <span v-if="stat.subValue !== null">{{
            formatNumber(stat.subValue, stat.subValueType || '')
          }}</span>
          {{ stat.subTitle }}
        </div>
        <div
          v-if="showDiffPill && stat.value !== 0 && !stat.change.isInfinity"
          class="diff-pill-margin1"
        >
          <k-diff-pill
            :with-background="showDiffPillWithBackground"
            :class="{ w55: !showDiffPillWithBackground }"
            :value="stat.change.value"
            :is-infinity="stat.change.isInfinity"
            :reverse-colors="stat.change.reverseColors"
          />
        </div>
      </div>
    </div>
    <span v-if="!loading && !stats.length" class="txt-center">No data is available to show</span>
    <b-loading-spinner v-if="loading" class="txt-center" />
  </div>
</template>

<script>
import formatNumber from '~/util/numeral';

export default {
  components: {
    BNumero: () => import(/* webpackChunkName: "b-numero" */ '~/components/elements/b-numero.vue'),
    KDiffPill: () =>
      import(/* webpackChunkName: "k-diff-pill" */ '~/components/elements/k-diff-pill.vue'),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    SemiCircle: () =>
      import(/* webpackChunkName: "semi-circle" */ '~/components/charts/semi-circle.vue'),
    BRadioTabGroup: () =>
      import(
        /* webpackChunkName: "b-radio-tab-group" */ '~/components/elements/b-radio-tab-group.vue'
      ),
  },
  props: {
    stats: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    windowType: {
      type: String,
      required: true,
      default: () => '',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDiffPill: {
      type: Boolean,
      required: false,
      default: true,
    },
    showDiffPillWithBackground: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => {
    return {
      showIOPacing: 0,
      showIOPacingTimeout: null,
      revenueConversionOptions: [
        {
          text: '%',
          active: true,
          disabled: false,
          infoText:
            'Ratio of revenue generated from advertising relative to the cost of that advertising, expressed as a percentage.',
          hideTooltipIcon: true,
        },
        {
          text: ':',
          active: false,
          infoText:
            'Ratio of revenue generated from advertising relative to the cost of that advertising, expressed as a ratio.',
          disabled: false,
          hideTooltipIcon: true,
        },
      ],
    };
  },
  computed: {
    formattedStats() {
      const selectedValue = this.revenueConversionOptions.filter((val) => val.active);
      return this.stats.map((stat) =>
        stat.title === 'ROAS' || stat.title === 'Inc. Reach %'
          ? { ...stat, valueType: selectedValue[0].text === '%' ? 'PERCENTAGE' : 'RATIO' }
          : stat
      );
    },
  },
  methods: {
    updateRevenueConversionOpt(value) {
      this.revenueConversionOptions = value;
    },
    formatNumber,
    getCheckoutUniquesLabel() {
      const prefix = this.selectedEvent.isRevenueEvent ? 'Checkout' : this.selectedEvent.eventLabel;
      return `${prefix} Uniques`;
    },
    formatIOPacing(value) {
      return formatNumber(Math.abs(value), 'PERCENTAGE', this.decimals);
    },
    ioPacingMouseEnter() {
      if (this.showIOPacingTimeout) {
        clearTimeout(this.showIOPacingTimeout);
      }

      this.showIOPacing = 1;
    },
    ioPacingMouseLeave() {
      this.showIOPacingTimeout = setTimeout(() => {
        this.showIOPacing = 0;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
span.initp4 {
  cursor: pointer;
}
.intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  width: 12rem;
  padding: 10px 12px;
  margin-left: -50px;
  font-size: 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #fdea85;
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
}
.intip4[data-tool-tip]::after {
  bottom: 100%;
  width: 230px;
  padding: 12px 15px;
  margin-left: -28px !important;
  font-size: 12px;
  font-weight: 400;
  color: #222;
  text-align: left;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 0.9);
  text-transform: none;
}
.intip4[data-tool-tip].sm-box::after {
  width: 180px !important;
}
.intip4[data-tool-tip]:hover::after {
  bottom: 115%;
  transform: scale(1);
}
.panel-title {
  display: inline-block;
  width: 100%;
  margin: 0.5rem 0;
  font-size: 0.875rem;
  text-align: center;
  letter-spacing: 0.07em;
}

.tachometer {
  color: var(--primarygreen);
}
.light-theme .pacing {
  .tpace-btn {
    background: #ecedf1 !important;
    font-weight: 400;
    color: #212325;
  }
  .pacing-summary {
    background-color: #fff;
    box-shadow: 0 3px 20px rgba(202, 209, 214, 0.8);
    .io-title {
      color: #536176;
      font-weight: 600;
    }
    .iopacing-wrapper {
      .data-wrap {
        .data {
          color: #7e8890;
          ::v-deep * {
            font-weight: 500 !important;
          }

          .fa-copy,
          .fa-check {
            color: #fff !important;
          }
        }
      }
    }
  }
}
::v-deep .pacing .pacing-summary .iopacing-wrapper .intip4:hover .initpHeadValueContainer {
  top: -200%;
  height: 38px;
}
::v-deep .revenue-conversion {
  position: relative;
  display: inline-block;
  .radio-tab-group-wrap.option-selection-cont {
    margin-left: 60px;
    position: relative;
    .radio-tab-wrap {
      width: 22px !important;
      height: 18.5px;
      padding: 0px !important;
      &:not(:last-child) {
        border-radius: 6px 0px 0px 6px;
      }
      &:not(:first-child) {
        border-radius: 0 6px 6px 0;
        // background-color: #7878783d;
      }
      .radio-tab-wrap-text {
        margin: auto;
        width: 50%;
        display: flex;
        font-size: 0.9em;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.pacing {
  position: relative;
  display: inline-block;

  svg {
    margin-right: 2px;
  }
  .tpace-btn {
    position: relative;
    display: inline-block;
    opacity: 0.8;
    cursor: pointer;
    margin-left: -1px;
    margin-top: -2px;
    font-size: 14px;
    background: #353b4f;
    padding: 3px 5px;
    border-radius: 50px;
  }
  .tpace-btn:hover {
    opacity: 1;
  }
  .pacing-summary {
    position: absolute;
    top: 170%;
    left: -85%;
    padding: 15px 0 15px 0px;
    border-radius: 5px;
    width: 330px;
    height: 280px;
    background-color: #282c33;
    box-shadow: 0px 3px 20px rgb(0 0 0 / 80%);
    .io-title {
      border-bottom: 1px solid rgb(74 101 131 / 20%);
      padding-bottom: 20px;
    }
    .txt-align-center {
      height: 180px !important;
    }
    .iopacing-wrapper {
      position: relative;
      margin: 2rem 1rem 0;

      .semi-circle {
        margin-left: 10px;
        top: 30px;
      }
      .data-wrap {
        position: absolute;
        &:nth-child(1) {
          top: -25px;
          left: 0;
          text-align: left;
        }
        &:nth-child(2) {
          top: 25px;
          bottom: 0;
          left: 0;
          text-align: left;
        }
        &:nth-child(4) {
          top: -25px;
          right: 0;
          text-align: right;
        }
        &:nth-child(5) {
          top: 25px;
          right: 0;
          bottom: 0;
          text-align: right;
        }
        .head {
          display: block;
          font-size: 12px;
          font-weight: 600;
          line-height: 1.2em;
          color: #646c86;
          text-transform: uppercase;
          letter-spacing: 0.02em;
        }
        .data {
          display: block;
          margin-bottom: 15px;
          font-size: 15px;
          line-height: 1.2em;
          font-weight: 500;
          text-transform: lowercase;
          color: #fff;
          ::v-deep * {
            font-size: 15px;
            line-height: 1.2em;

            .fa-check,
            .fa-copy {
              font-size: 18px;
              top: 10px;
              color: #fff !important;
            }
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media screen {
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .opt-btn:hover {
    color: var(--primarycolor);
  }
}

.title-plus-drop {
  position: absolute;
  z-index: 2;
  display: none;
  padding: 10px 10px 10px 0;
  margin: 10px 0 0 -10px;
  text-transform: none;
  background-color: #383a44;
  .psub {
    display: block;
    padding-left: 10px;
    font-size: 12px;
    color: #a4acbb;
    &.psub-title {
      font-size: 13px;
      font-weight: 600;
      color: #eee;
    }
  }
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-times:before {
  content: '\f00d';
}
.fa-clock-o:before {
  content: '\f017';
}
.fa-caret-down:before {
  content: '\f0d7';
}

.stat-cnt {
  display: flex;
  justify-content: space-between;
}
.mid-area-1-amt::first-letter(1) {
  color: red !important;
}
label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + label:before {
  display: inline-block;
  font-family: 'FontAwesome';
}
input[type='checkbox'] + label:after {
  display: inline-block;
  font-family: 'FontAwesome';
}
input[type='checkbox'] + label:before {
  position: relative;
  top: -2px;
  left: 2px;
  z-index: 2;
  font-size: 14px;
  font-weight: 600;
  color: var(--primarycolor);
  letter-spacing: 10px;
  content: '\f00c';
  opacity: 0;
}
input[type='checkbox']:checked + label:before {
  font-weight: 600;
  letter-spacing: 10px;
  content: '\f00c';
  opacity: 1;
}
input[type='checkbox'] + label::after {
  position: absolute;
  left: 0px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(150, 150, 150, 0.4);
  content: '\f1db';
}
input[type='checkbox']:checked + label::after {
  color: var(--primarycolor);
}
.mid-w-3 {
  display: inline-flex;
  justify-content: space-between;
}
.mid-w-3 {
  justify-content: space-between;
  width: 100%;
  min-height: 150px;
}
.mid-w-3:first-child {
  justify-content: space-between;
  width: 100%;
}
.light-theme {
  .mid-area-1 {
    color: #212325;
    box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
    background-color: #fff;
    border: 1px solid rgb(165 173 178 / 22%);
    border-radius: 8px;
  }
}
.mid-area-1 {
  position: relative;
  display: inline-block;
  padding: 28px;
  margin-right: 25px;
  color: #fff;
  text-align: left;
  background-color: #212429;
  border-radius: 3px;
}
.mid-area-1-cont {
  display: inline-block;
  width: 100%;
  margin-right: 2rem;
}

.mid-area-1-cont:last-child {
  margin-right: 0;
}

.mid-area-1-cont-title {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  &:hover {
    .title-plus-drop {
      display: block;
    }
  }
}
.light-theme {
  .mid-area-1-cont-title {
    font-weight: 600;
    color: #536176;
  }
}
.mid-area-1-amt {
  margin: 30px 0 0 0;
  font-size: 35px;
  font-weight: 300;
}
.mid-area-1-amt-label {
  height: 15px;
  font-size: 12px;
  color: #cad1d6;
  text-transform: capitalize;
}
.muted {
  color: #687982;
}
.mid-area-1-amt-label span {
  margin-right: 5px;
  font-size: 13px;
  color: #cad1d6;
}
.amtlabel {
  font-size: 20px;
}
.mid-area-1-change {
  display: inline-block;
}
.cont-1ast {
  margin-right: 0px;
}
.form-control {
  box-sizing: border-box;
  width: 60px;
  padding: 12px 15px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
}
.label-txt {
  font-size: 12px;
}
.col-2 {
  float: left;
  width: 60px;
}
.flp-btn:hover {
  color: #fff !important;
  background-color: #424242 !important;
}
.btn-size3 {
  padding: 10px 15px;
  font-size: 12px;
}
.flp-btn {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--primarycolor);
  -webkit-border-radius: 0px;
  border-radius: 0px;
  border-radius: 3px;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-touch-callout: none;
}
.fa.fa-clock-o:hover {
  color: var(--primarycolor) !important;
}

// CSS for options - START

.flp-panel-top-right {
  position: absolute;
  right: 0;
  z-index: 11;
  margin: -20px 10px 0 0;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-16 {
  width: 1em;
}
.txt-center {
  position: absolute;
  width: 85%;
  margin-top: 15px;
  font-size: 13px;
  color: #bac0c5;
  text-align: center;
}
.mid-area > .mid-area-1:nth-child(2) {
  .stat-cnt {
    .mid-area-1-cont:nth-child(1) {
      margin-right: 1rem;
      .wide-120:nth-child(1) {
        width: 120px;
      }
      .wide-140:nth-child(1) {
        width: 140px;
      }
    }
  }
}
.diff-pill-margin1 {
  margin-top: 1rem;
  .add-margin.zero {
    margin-left: 0;
  }
  .w55 {
    width: 55px !important;
    padding-left: 0;
  }
}
</style>
